import React, { Component } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import './SayHello.css';
const axios = require('axios');

class SayHello extends Component {
    constructor(props) {
        super(props);
        this.state = {
            randomSentence: null
        };
    }

    getRandomSentence() {
        fetch('https://api.chopeposts.com/sentence', {
            crossDomain: true,
            method: 'GET'
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        randomSentence: result.sentence
                    });
                },
                (error) => {
                    this.setState({
                        randomSentence: 'Unknown'
                    })
                }
            )
    }

    componentDidMount() {
        this.getRandomSentence()
    }

    handleClick(id) {
        let success = false
        axios({
            method: 'post',
            url: 'https://api.chopeposts.com/sentence',
            data: {
                sentiment: id,
                sentence: this.state.randomSentence
            }
        }).then(function(response) {
            console.log(response)
        })

        
        this.getRandomSentence()
    }

    render() {
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs>
                        <Typography variant="h4">Sentence Sentiment Selector</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs>
                        <Typography variant="subtitle1">Pick the sentiment of the randomly generated sentence below</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs>
                        <Typography variant="body1" className="Sentence">{this.state.randomSentence}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Button variant="contained" color="primary" onClick={() => this.handleClick(0)}>Happy</Button>
                    </Grid>               
                    <Grid item xs>
                        <Button variant="contained" color="secondary" onClick={() => this.handleClick(1)}>Sad</Button>    
                    </Grid>     
                </Grid>
            </div>
        )
    }
}

export default SayHello;